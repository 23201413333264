import { Link, NavLink } from 'react-router-dom'
import CardProject from '../cardProjects/CardProject'
import './OurProjectSection.css'
import Slider from '../slider/Slider'

export default function OurProjectSection() {
  return (
    <>
      <div className="section-4 container">
        <div className="top-section-4">
          <div className="title-our-project-section-4">
            <p className="section-3-services-text"> ------ مشاريعنا</p>
            <h1 className="head-text-section-2 title-section4">
              أعلى
              <span className="your-choose"> مستويات </span>
              الجودة والاحترافية
            </h1>
          </div>
          <div className="desc-our-project-section-4">
            <p className="desc-section-t desc-section-4-text">
           في (أبعــاد العقارية) يتخطي التمييز كل التوقعات, حيث نري فى كل مشروع عقاري قصة نجاح فريدة وفريقنا يعمل بجد لضمان تحقيق اعلى مستويات التمييز والجودة
            </p>
            <button className="btns-section-2"> اعرف اكثر </button>
          </div>
        </div>
      </div>
      <Slider />
      <div className="section-4-upd container">
        <div className="bottom-section-4">
          <div className="left-bottom-section-4">
            <img
              src={require("../../assats/section4.png")}
              alt=""
              width="80%"
            />
          </div>
          <div className="right-bottom-section-4">
            <p className="desc-bottom-section-4"> اعرض عقارك للبيع  ----- </p>
            <h1 className="title-bottom-section-4">كل لحظة تمثل فرصة جديدة</h1>
            <p className="desc-bottom-section-4">
             ابدأ رحلتك ببيع عقارك مع أبعاد العقارية , لنضمن لك تجربة بيع استثنائية ورحلة شراء سهلة ويسيرة لتحصل علي افضل جودة بأفضل الخدمات
            </p>
            <div className="container-button container-button-home-page">
              <button className="btns-section-2 btn-read-more btn2-section-4">
                اعرف أكثر
              </button>
              <NavLink to={"/contactus"}>
                <button className="btns-section-2 btn-read-more btn2-section-4">
                  تواصل معنا
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}