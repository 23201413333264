import { useEffect, useState } from "react";
import "./CardProjects2.css";
import noImg from "../../assats/noImgPro.png";
import axios from "axios";
import ba from "../../assats/logo2.png";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { $ServerUrl } from "../../store";
export default function CardProjects2() {
  const [currentPage, setCurrentPage] = useState(1);
  const [serverUrl] = useRecoilState($ServerUrl);
  const [projects, setProjects] = useState([]);
  function getAllProjects() {
    let server = `${serverUrl}/projects`;
    axios
      .get(server, {
        params: {
          populate: "*",
        },
      })
      .then((res) => {
        setProjects(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getAllProjects();
  }, []);
   {/* -------------start pagination-------------- */}
  const project_per_page = 6;
  const pages = Math.ceil(projects.length / project_per_page);
  const generatedPages = [];
  for (let i = 1; i <= pages; i++) {
    generatedPages.push(i);
  }
  const startIndex = (currentPage - 1) * project_per_page;
  const finishIndex = currentPage * project_per_page;
  const orderProjects = projects.slice(startIndex, finishIndex);
 {/* -------------end pagination-------------- */}
  return (
    <>
     {/* -------------map get projects-------------- */}
      {orderProjects.map((project, index) => (
        <div className="card2-projects-page" key={index}>
          <div
            className="container-image-project"
            style={{ backgroundImage: `url(${ba})` }}
          >
            <img
              src={
                // project.attributes.cover_photo.data
                //   ? `${
                //       serverUrl.includes("localhost")
                         serverUrl.split("/api")[0] +
                          project.attributes.cover_photo.data.attributes.url
                  //       : project.attributes.cover_photo.data.attributes.url
                  //   }`
                  // : noImg
              }
              alt="project image"
              className="project-image-card2"
            />
          </div>
          <div className="container-ditalse-projects-card">
            <div className="container-project-name-card2">
              <h1 className="title-project-card2">
                {project.attributes.project_name}
              </h1>
            </div>
            <div className="price-project-card2 display-card2">
              <p className="color-text price-no">
                <span className="price-item"> رس </span>{" "}
                <span>
                  {project.attributes.min_price}{" "}
                  <span className="price-item"> تبدأ من </span>
                </span>
              </p>
              <p className="color-text">السعر</p>
            </div>
            <div className="space-project-card2 display-card2">
              <p className="color-text price-no">
                <span className="price-item">م</span>{" "}
                <span>
                  {project.attributes.min_area} - {project.attributes.max_area}
                </span>
              </p>
              <p className="color-text">المساحات</p>
            </div>
            <div className="roms-project-card2 display-card2">
              <p className="color-text price-no">
                <span className="price-item">
                  {" "}
                  {project.attributes.min_rooms}{" "}
                </span>{" "}
                <span>تبدأ من </span>
              </p>
              <p className="color-text">الغرف</p>
            </div>
            <div className="features-project-card2 display-card2">
              <p className="color-text price-no">
                <span className="price-item">توفر</span>
              </p>
              <p className="color-text">مميزات</p>
            </div>
          </div>
          <Link to={`/project/${project.id}`}>
            <button className="btn-project-card2">تفاصيل</button>
          </Link>
        </div>
      ))}

      {/* -------------pagination view-------------- */}
      <div className="pag">
        {generatedPages.map((item, index) => (
          <div
            className={
              currentPage === item
                ? "pagenation-container activated"
                : "pagenation-container"
            }
            onClick={() => setCurrentPage(item)}
            key={index}
          >
            <h3 key={index}>{item}</h3>
          </div>
        ))}
      </div>
    </>
  );
}
